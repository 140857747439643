
import React from "react";
import { HeaderProps } from "react-big-calendar";
import moment from "moment";
import { IconButton, Popover } from "@mui/material";
import { WarningAmberOutlined } from "../../../../Icons";
import "./Week.css";
import { CloseRounded } from "@material-ui/icons";

interface Props extends HeaderProps {
  selectedStaff: number | null;
  gaps: Record<string, string[]> | null;
  conflicts: Record<string, string[]> | null;
}

const Header = React.memo((props: Props) => {
  const { label, date: datetime, selectedStaff, gaps, conflicts } = props;
  const weekdate = moment(datetime).format("yyyy-MM-DD");

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const errors = React.useMemo(() => {
    let errors: {
      gaps: string[] | null;
      conflicts: string[] | null
    } = {
      gaps: null,
      conflicts: null
    };

    if (gaps) {
      Object.entries(gaps).forEach(([date, messages]) => {
        if (weekdate === date && messages.length > 0) errors.gaps = messages;
      });
    }

    if (conflicts) {
      Object.entries(conflicts).forEach(([date, messages]) => {
        if (weekdate === date && messages.length > 0) errors.conflicts = messages;
      });
    }

    if (!errors.gaps && !errors.conflicts) return null;

    return errors;
  }, [gaps, conflicts]);

  return (
    <div className="week_view__header" data-error={!selectedStaff && !!errors}>
      <span>{label}</span>

      {!selectedStaff && !!errors && (
        <>
          <IconButton color="error" onClick={handleClick}>
            <WarningAmberOutlined />
          </IconButton>
          <Popover
            id={open ? "conflicts-gaps-popover" : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              paper: "week__view__popover"
            }}
          >
            <div className="week__view__popover__header">
              <h1>Warning - {moment(datetime).format("ddd D")}</h1>
              <IconButton onClick={handleClose}>
                <CloseRounded />
              </IconButton>
            </div>

            <div className="week__view__popover__wrapper">
              {!!errors.gaps && (
                <div className="week__view__popover__section">
                  <h2>Gaps</h2>
                  <ul className="week__view__popover__list">
                    {errors.gaps.map((message, index) => (
                      <li key={index}>{message}</li>
                    ))}
                  </ul>
                </div>
              )}

              {!!errors.conflicts && (
                <div className="week__view__popover__section">
                  <h2>Conflicts</h2>
                  <ul className="week__view__popover__list">
                    {errors.conflicts.map((message, index) => (
                      <li key={index}>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Popover>
        </>
      )}
    </div>
  );
});

export { Header };
