Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpossupportpage1";
exports.labelBodyText = "cfpossupportpage1 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getAllSupportCategoriesEndPoint = "bx_block_cfpossupportpage1/pos_supports/support_category_list"
exports.getCategoryWiseFaqEndPoint = "bx_block_cfpossupportpage1/pos_supports/"
// Customizable Area End