import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { debounce } from "lodash";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getResponseData } from "../../../components/src/utils";
import { DeliveryArea } from "./types";
import { error } from "console";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  role: string;
  loading: boolean;
  deliveryAreas: DeliveryArea[];
  selectedAddress: DeliveryArea | null;
  openFlagAreaPopup: boolean;
  successPopup: {
    open: boolean;
    message?: string;
  };
  errorMessage: {
    open: boolean;
    message?: string | string[];
  },
  addAddressPopupOpen: boolean;
  updateAddressPopup: {
    open: boolean;
    data: Partial<DeliveryArea> | null;
  };
  deleteAddressPopup: {
    open: boolean;
    id: number | null;
  },
  postCodeOptions: { id: number; main_text: string; secondary_text: string }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfdeliveryareamanagement1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deliveryAreasCallId: string = "";
  addDeliveryAreaCallId: string = "";
  updateDeliveryAreaCallId: string = "";
  deleteDeliveryAreaCallId: string = "";
  flagDeliveryAreaCallId: string = "";
  getPostCodeSuggestionCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getPostCodes = debounce(this.getPostCodes.bind(this), 500);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      role: "",
      loading: false,
      deliveryAreas: [],
      selectedAddress: null,
      openFlagAreaPopup: false,
      successPopup: {
        open: false,
        message: "",
      },
      errorMessage: {
        open: false,
        message: "Something went wrong. Please try again later.",
      },
      addAddressPopupOpen: false,
      updateAddressPopup: {
        open: false,
        data: null
      },
      deleteAddressPopup: {
        open: false,
        id: null
      },
      postCodeOptions: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const { response, callId, error } = getResponseData(message);

    if (!response) return;
    if (error) {
        runEngine.debugLog("API Error", error)
        return;
    };

    const handler = this.getMessageHandler(callId);
    if (handler) handler(response);

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    const role = sessionStorage.getItem("role") ?? "";
    this.setState({ role });
    await this.getToken();
    this.getDeliveryAreas();
  }

  getToken = async () => {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  };

  getMessageHandler(callId: string) {
    const messageHandlers: {
      [x: string]: (response: any) => void;
    } = {
      [this.deliveryAreasCallId]: this.handleDeliveryAreas,
      [this.addDeliveryAreaCallId]: this.handleAddDeliveryArea,
      [this.updateDeliveryAreaCallId]: this.handleUpdateDeliveryArea,
      [this.deleteDeliveryAreaCallId]: this.handleDeleteDeliveryArea,
      [this.flagDeliveryAreaCallId]: this.handleFlagDeliveryArea,
      [this.getPostCodeSuggestionCallId]: this.handlePostCodeSuggestions,
    };

    return messageHandlers[callId];
  }

  handleAddDeliveryArea = (response: any) => {
    if (response?.error) {
      this.setState({
        errorMessage: {
          open: true,
          message: response.error
        }
      });

      return;
    }

    if (response?.message) {
      this.setState({
        successPopup: {
          open: true,
          message: response.message
        }
      }, this.handleAddAddressPopupClose);
    }
  };

  handleUpdateDeliveryArea = (response: any) => {
    if (response?.error) {
      this.setState({
        errorMessage: {
          open: true,
          message: response.error
        }
      });

      return;
    }

    if (response?.message) {
      this.setState({
        successPopup: {
          open: true,
          message: response.message
        }
      }, this.handleUpdateAddressPopupClose);
    }
  };

  handleDeleteDeliveryArea = (response: any) => {
    if (response) {
      this.setState({
        successPopup: {
          open: true,
          message: "Delivery Area has been deleted successfully!"
        }
      }, this.handleDeleteAreaPopupClose);
    }
  };

  handlePostCodeSuggestions = (response: any) => {
    if (response?.data) {
        this.setState({
            postCodeOptions: response.data.map((postcode: any, idx: any) => ({ ...postcode, id: idx }))
        });
    };
  };

  handleFlagDeliveryArea = (response: any) => {
    if (response?.message) {
      this.setState({
        successPopup: {
          open: true,
          message: response.message,
        },
      }, () => {
        this.getDeliveryAreas();
        this.handleFlagAreaPopupClose();
      });
    } else {
      this.setState({
        errorMessage: { open: true },
      });
    }
  };

  handleDeliveryAreas = (response: any) => {
    if (response) {
      this.setState({ deliveryAreas: response, loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  getDeliveryAreas = () => {
    this.handleAddAddressPopupClose();
    this.handleUpdateAddressPopupClose();
    this.setState({ loading: true });
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deliveryAreasCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deliveryAreasAPI.endPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  addDeliveryArea = (data: Partial<DeliveryArea>) => {
    const addDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addDeliveryAreaCallId = addDataMsg.messageId;
    addDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deliveryAreasAPI.endPoint
    );
    addDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    addDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    const body = {
      delivery_area: {
        ...data,
        minimum_order: parseInt(data.minimum_order ?? "0"),
        delivery_charge: parseInt(data.delivery_charge ?? "0"),
      }
    }
    addDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(addDataMsg.id, addDataMsg);
  };

  updateDeliveryArea = (id: number, data: Partial<DeliveryArea>) => {
    const updateDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateDeliveryAreaCallId = updateDataMsg.messageId;
    updateDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deliveryAreasAPI.endPoint}/${id}`
    );
    updateDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    updateDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    const body = { delivery_area: data };
    updateDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(updateDataMsg.id, updateDataMsg);
  };

  deleteDeliveryArea = () => {
    const deleteDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteDeliveryAreaCallId = deleteDataMsg.messageId;
    deleteDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deliveryAreasAPI.endPoint}/${this.state.deleteAddressPopup.id}`
    );
    deleteDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    deleteDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(deleteDataMsg.id, deleteDataMsg);
  };

  flagDeliveryArea = (id: number, flag_reason: string) => {
    const flagDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.flagDeliveryAreaCallId = flagDataMsg.messageId;
    flagDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deliveryAreasAPI.endPoint}/${id}/flag`
    );
    flagDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    flagDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    const body = { flag_reason };
    flagDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(flagDataMsg.id, flagDataMsg);
  };

  getPostCodes=(postcode:string)=>{
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPostCodeSuggestionCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCodeSuggestion.endPoint}${postcode}${configJSON.postCodeSuggestion.type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFlagAreaPopupOpen = (address: DeliveryArea) =>
    this.setState({
      selectedAddress: address,
      openFlagAreaPopup: true,
    });

  handleFlagAreaPopupClose = () =>
    this.setState({
      selectedAddress: null,
      openFlagAreaPopup: false
    });

  handleSuccessPopupClose = () =>
    this.setState({
      successPopup: { open: false }
    }, this.getDeliveryAreas);

  handleErrorPopupClose = () =>
    this.setState({
      errorMessage: { open: false }
    });

  handleAddAddressPopupOpen = () =>
    this.setState({
      addAddressPopupOpen: true
    });

  handleAddAddressPopupClose = () =>
    this.setState({
      addAddressPopupOpen: false,
      postCodeOptions: [],
    });

  handleUpdateAddressPopupOpen = (data: Partial<DeliveryArea>) =>
    this.setState({
      updateAddressPopup: {
        open: true,
        data: data
      }
    });

  handleDeleteAreaPopupOpen = (id: number) =>
    this.setState({
      deleteAddressPopup: {
        open: true,
        id
      }
    });

  handleDeleteAreaPopupClose = () =>
    this.setState({
      deleteAddressPopup: {
        open: false,
        id: null
      }
    });

  handleUpdateAddressPopupClose = () =>
    this.setState({
      updateAddressPopup: {
        open: false,
        data: null
      },
      postCodeOptions: [],
    });

  // Customizable Area End
}
