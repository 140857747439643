import React from "react";
import moment from "moment";
import "moment-timezone";
import { Calendar, Event, momentLocalizer, View, ViewsProps } from 'react-big-calendar'
import Toolbar from "./components/Toolbar";
import { Header as MonthHeader } from "./components/Month";
import { Header as WeekHeader } from "./components/Week";
import { EventComponent } from "./components/EventComponent";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";
import { getSessionStorage } from "../../utils";

interface Props {
  selectedStaff: number | null;
  selectedRole: string | null;
  recievedView?: View;
  recievedViews?: ViewsProps<Event, object>;
  events: Event[];
  updateEvents: (events: Event[]) => void;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  totalShiftHours: string;
  openLogin: (event: Event) => void;
  gaps: Record<string, string[]> | null;
  conflicts: Record<string, string[]> | null;
  openWeeklyNotes: (start: string, end: string) => void;
};

const WingosCalendar = React.memo(({ selectedStaff, selectedRole, events, recievedView, recievedViews, openShiftPopup, totalShiftHours, openLogin, gaps, conflicts, openWeeklyNotes }: Props) => {
  const [view, setView] = React.useState<View>("month");
  const [views, setViews] = React.useState<ViewsProps<Event, object>>({ month: true });
  const shifts = React.useMemo<Event[]>(
    () =>
      events.filter(event =>
        selectedRole ? event.resource?.role === selectedRole : true
      ),
    [events, selectedRole]
  );

  React.useEffect(() => {
    if (recievedView) {
      setView(recievedView);
    }
  }, [recievedView]);

  React.useEffect(() => {
    if (recievedViews) {
      setViews(recievedViews);
    }
  }, [recievedViews]);

  return (
    <Calendar
      defaultView="month"
      view={view}
      views={views}
      onView={(view: View) => setView(view)}
      onShowMore={() => setView("week")}
      tooltipAccessor={null}
      events={shifts}
      localizer={localizer}
      className="calendar__view"
      components={{
        toolbar: (props) => <Toolbar
          {...props}
          selectedStaff={selectedStaff}
          selectedRole={selectedRole}
          openAddShift={openShiftPopup}
          totalShiftHours={totalShiftHours}
          gaps={gaps}
          conflicts={conflicts}
          openWeeklyNotes={openWeeklyNotes}
        />,
        month: { header: MonthHeader },
        week: { header: (props) => <WeekHeader {...props} selectedStaff={selectedStaff} gaps={gaps} conflicts={conflicts} /> },
        day: { header: MonthHeader },
        timeSlotWrapper: (props) => <div className="calendar__time_slot_wrapper">{props.children}</div>,
        resourceHeader: (props) => <div>{props.children}</div>,
        event: (props) => <EventComponent {...props} key={props.event.resource.id} selectedStaff={selectedStaff} selectedRole={selectedRole} activeView={view} openShiftPopup={openShiftPopup} openLogin={openLogin} />,
        eventWrapper: (props) => <div>{props.children}</div>,
        eventContainerWrapper: (props) => <div>{props.children}</div>
      }}
    />
  );
});

const settings = getSessionStorage("restaurant_settings");
const time_zone = settings?.time_zone;

moment.tz.setDefault(time_zone);
moment.updateLocale('en', {
  week: {
    dow: 1 // 0 represents Sunday as the start of the week,
  },
});

const localizer = momentLocalizer(moment)

export default WingosCalendar;
