Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  exports.contentType = "application/json";
  exports.getMethod = "GET";
  exports.postMethod = "POST";
  exports.patchMethod = "PATCH";
  exports.customerInformationAPI = {
    endPoint: "bx_block_cfposcustomermanagementfe/customers",
    method: "GET"
  };
  