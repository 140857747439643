import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import CfposcustomermanagementfeController from "../../../../../blocks/cfposcustomermanagementfe/src/CfposcustomermanagementfeController";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  token: string;
  data: any;
  addresses:any[];
  activityLog: {
    message: string;
    timestamp: string;
    updatedAttributes: Record<string, any>;
    changedAttributes: any;
    changes: any;
    timeAgo: string;
  }[];
  account_status: boolean;
  openUpdateCustomer: boolean;
  availableIdentityTypes: { id: number; identity_type: string }[];
  availableRoles: { id: number; name: string }[];
  errors?: string[];
  validationErrors: Record<string, string>;
  notes: any[];
  openConfirmation: boolean;
  openManageNote: boolean;
  openNoteSuccess: boolean;
  openFilters: boolean;
  activeFilterTab: string;
  editNote: {
    id: number;
    note: string;
  } | null;
  expiryPopupAnchorEl: HTMLElement | null;
  searchQuery: string | null;
  orderTypeFilters: Record<string, boolean>;
  paymentTypeFilters: Record<string, boolean>;

  orderStatusFilters: Record<string, boolean>;

  filteredOrders: any[];
  customerOrders: any;
  addModalOpen:boolean;
  newCustomer: { // Stores the form data for the new customer
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    date_of_birth: string;
    address: {

      city:string;
      country: string;
      address: string;
      postCode: string;
      addressDirection: string;
      defaultAddress: boolean;
    };
    email_communication?: boolean,
    mobile_communication?: boolean,
  };

}

interface SS {}

export default class ViewCustomerController extends BlockComponent<
  Props,
  S,
  SS> {
  customerDetailsCallId: string = "";
  accountStatusCallId: string = "";
  toggleStatusCallId: string = "";
  activityLogCallId: string = "";
  updateCustomerCallId: string = "";
  availableIdentityCallId: string = "";
  availableRolesCallId: string = "";
  notesCallId: string = "";
  addNoteCallId: string = "";
  updateNoteCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.controller.openEditModal = this.controller.openEditModal.bind(this.controller);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      data: {attributes:{first_name:"",last_name:"",email:"",phone_number:"",DOB:"",Gender:""}},
      addresses:[],
      activityLog: [],
      account_status: false,
      openUpdateCustomer: false,
      availableIdentityTypes: [],
      availableRoles: [],
      validationErrors: {},
      notes: [],
      openConfirmation: false,
      openManageNote: false,
      openNoteSuccess: false,
      openFilters: false,
      activeFilterTab:'all',
      editNote: null,
      expiryPopupAnchorEl: null,
      searchQuery: '',
      orderTypeFilters: {
        "collection": false,
        "delivery": false,
        "telephone": false,
      },
      paymentTypeFilters: {
        "Credit Card": false,
        Cash: false,
        "Google Pay": false,
        "Apple Pay": false,
      },
      orderStatusFilters: {
        "Order Placed": false,
        "Order Accepted": false,
        Cooking: false,
        Ready: false,
        "Picked Up": false,
        Delivered: false,
        Collected: false,
        "Cancelled": false,
        "Payment Failed":false,
        "Returned":false,
        "Payment Pending":false,
        "Cancelled and Refunded":false,

      },
      filteredOrders: [],
      customerOrders:{orders:[]},
      addModalOpen:false,
      newCustomer: {
        firstName: "",
        lastName: "",
        email: "",
        countryCode:'44',
        phoneNumber: "",
        date_of_birth: "",
        address: {
          city: "",
          country: "",
          address: "",
          postCode: "",
          addressDirection: "",
          defaultAddress: false,
        },
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    console.log('componentDidMount view customer controller', this.state.customerOrders)
    super.componentDidMount();
    await this.getToken();
    this.getCustomerDetails();
  }
  controller = new CfposcustomermanagementfeController(this.props);
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      prevState.customerOrders.orders !== this.state.customerOrders.orders &&
      this.state.customerOrders.orders.length > 0
    ) {
      const filtered = this.state.customerOrders.orders.map(
        (order: any) => order.attributes
      );
      this.setState({ filteredOrders: filtered });
    }
  }
  
  
  getMessageHandler(callId: string, apiResponse: any) {
    const messageHandlers = {
      [this.customerDetailsCallId]: () => this.handleCustomerDetails(apiResponse),
      [this.updateCustomerCallId]: () => this.handleUpdateCustomer(apiResponse),
    };
  
    if (!messageHandlers[callId]) {
      return null;
    }
  
    return messageHandlers[callId];
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const handler = this.getMessageHandler(callId, apiResponse);
    if (handler) handler();
    if (errorMessage) runEngine.debugLog("API Error", errorMessage);
  }

  async getToken() {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  }


  handleUpdateCustomer(data: any) {
    if (data.errors) {
      this.setState({ errors: data.errors });
      return;
    }

    this.handleUpdateCustomerClose();
    this.getCustomerDetails();
  }


handleSearch = (event: any) => {
  const query = event.target.value.toLowerCase();
  const filtered = this.state.customerOrders?.orders
    .map((order: any) => order.attributes)
    .filter(
      (order: any) =>
        order.order_number.toLowerCase().includes(query) 
    ) || [];

  this.setState({ searchQuery: query, filteredOrders: filtered });
};


handleFilterChange = (filterCategory: keyof S, filterName: string) => {
  this.setState((prevState) => ({
    ...prevState,
    [filterCategory]: {
      ...(prevState[filterCategory] as Record<string, boolean>),
      [filterName]: !(
        prevState[filterCategory] as Record<string, boolean>
      )[filterName],
    },
  }));
};

handleFiltersApply = () => {
  const { customerOrders, orderTypeFilters, paymentTypeFilters, orderStatusFilters } = this.state;

  const filteredOrders = customerOrders?.orders
    .map((order: any) => order.attributes)
    .filter((order: any) => {
      const orderTypeMatch =
        Object.keys(orderTypeFilters).every((type) => !orderTypeFilters[type]) ||
        orderTypeFilters[order.order_type];
      const paymentTypeMatch =
        Object.keys(paymentTypeFilters).every((type) => !paymentTypeFilters[type]) ||
        paymentTypeFilters[order.payment_type];
      const orderStatusMatch =
        Object.keys(orderStatusFilters).every((status) => !orderStatusFilters[status]) ||
        orderStatusFilters[order.status];
      return orderTypeMatch && paymentTypeMatch && orderStatusMatch;
    });

  this.setState({ filteredOrders, openFilters: false });
};


handleFiltersReset = () => {
  this.setState({
    orderTypeFilters: {
      Collection: false,
      Delivery: false,
      Telephone: false,
    },
    paymentTypeFilters: {
      "Credit Card": false,
      Cash: false,
      "Google Pay": false,
      "Apple Pay": false,
    },
    orderStatusFilters: {
      "Order Placed": false,
        "Order Accepted": false,
        Cooking: false,
        Ready: false,
        "Picked Up": false,
        Delivered: false,
        Collected: false,
        "Cancelled": false,
        "Payment Failed":false,
        "Returned":false,
        "Payment Pending":false,
        "Cancelled and Refunded":false,
    },
    filteredOrders: this.state.customerOrders?.orders.map((order: any) => order.attributes),
  });
};


  handleCustomerDetails = (apiResponse: any) => {
    if (apiResponse.customer) {
      const customer = apiResponse.customer;
      const addreses = customer.addresses

      const {orders} = customer;
      

      this.setState({ data: customer, customerOrders: {orders: [...orders]}, addresses:addreses });
      if(this.state.customerOrders.length){
        this.setState({
          filteredOrders: this.state.customerOrders?.orders?.map((order: any) => order?.attributes),
        });
      }
      
    }
  };


  getCustomerDetails = () => {
    const id = this.props.navigation?.getParam("customerId");
   
  
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.customerDetailsCallId = getDataMsg.messageId; 
  
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerInformationAPI.endPoint + `/${id}`
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.customerInformationAPI.method);
    
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCustomer = (data: any) => {
    const id = this.props.navigation?.getParam("id");
    const formData = this.createFormData(data);

    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomerCallId = postDataMsg.messageId;
    postDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateCustomerAPI.endPoint + `/${id}`);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {
      token: this.state.token,
    });
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateCustomerAPI.method);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);

    return { success: true };
  };

  
  createFormData = (data: any) => {
    const formData = new FormData();
    formData.append(
      "account[customer_id]",
      data.customerId
    );
    data.roles.forEach((role: number) => {
      formData.append("account[role_ids][]", role.toString());
    });
    formData.append("account[first_name]", data.firstName);
    formData.append("account[last_name]", data.lastName);
    formData.append("account[country_code]", data.countryCode);
    formData.append("account[phone_number]", data.phoneNumber.replace(data.countryCode, ""));
    formData.append("account[full_phone_number", data.phoneNumber);
    formData.append("account[identity_type]", data.identityType);

    if (data.identityDocument.length > 0 && data.identityDocument[0] instanceof File)
      formData.append("account[identity_document]", data.identityDocument[0]);

    formData.append("account[permit_require]", JSON.stringify(data.permitRequired));
    formData.append("account[post_code]", data.postCode);
    if (data.permitRequired) {
      if (data.visaDocument.length > 0 && data.visaDocument[0] instanceof File)
        formData.append("account[visa]", data.visaDocument[0]);
      formData.append("account[expiry_date]", data.expiryDate);
    }
    formData.append("account[email]", data.email);
    formData.append("account[password]", data.password);

    return formData;
  };

  handleUpdateCustomerOpen = () => {
    this.setState({ openUpdateCustomer: true });
  };

  handleFiltersOpen = () => {
    this.setState({ openFilters: true });
  };



  handleFiltersClose = () => {
    this.setState({ openFilters: false });
  };



  handleUpdateCustomerClose = () => {
    this.setState({ openUpdateCustomer: false, errors: undefined, validationErrors: {} });
  };

  confirmClose = () => {
    this.setState({ openConfirmation: true });
  }

  handleConfirmationClose = () => {
    this.setState({ openConfirmation: false });
  }


  discardChanges = () => {
    this.handleUpdateCustomerClose();
    this.handleConfirmationClose();
  }


  formatDateTime(dateTimeStr: string): string {
    const date = new Date(dateTimeStr);

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; 

    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${month} ${day}, ${year} at ${hours}:${formattedMinutes} ${ampm}`;
  }

  openExpiryPopup = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ expiryPopupAnchorEl: event.currentTarget });
  };

  closeExpiryPopup = () => {
    this.setState({ expiryPopupAnchorEl: null });
  }

  handleEditAction = (customer:any) => {
    console.log('Customer:', customer,this.controller.state.addModalOpen);
    this.controller.openEditModal(customer);
  };
}
