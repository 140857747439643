import { z } from "zod";
import moment from "moment";

const ShiftSchema = z
  .object({
    id: z.number(),
    shift_date: z
      .string()
      .refine((value) => moment(value, "YYYY-MM-DD").isValid(), {
        message: "Invalid shift date format.",
      }),
    till_date: z
      .string()
      .optional()
      .refine((value) => !value || moment(value, "YYYY-MM-DD").isValid(), {
        message: "Invalid till date format.",
      }),
    start_time: z.string().refine((value) => moment(value, "HH:mm").isValid(), {
      message: "Start time cannot be empty.",
    }),
    end_time: z.string().refine((value) => moment(value, "HH:mm").isValid(), {
      message: "End time cannot be empty.",
    }),
    one_week: z.boolean(),
  })
  .superRefine((data, ctx) => {
    const shiftDate = moment(data.shift_date, "YYYY-MM-DD");

    // Validate till_date only if it exists
    if (data.till_date) {
      const tillDate = moment(data.till_date, "YYYY-MM-DD");
      if (!tillDate.isSameOrAfter(shiftDate)) {
        ctx.addIssue({
          path: ["till_date"],
          message: "Till date must not be before shift date.",
          code: z.ZodIssueCode.custom,
        });
      }
    }

    // Start time should be before end time
    const startTime = moment(data.start_time);
    const endTime = moment(data.end_time);

    if (!endTime.isAfter(startTime)) {
      ctx.addIssue({
        path: ["end_time"],
        message: "End time must be after start time.",
        code: z.ZodIssueCode.custom,
      });
    }

    // Start time and end time must have a difference of at least 1 hour
    const durationInMinutes = endTime.diff(startTime, "minutes");
    if (durationInMinutes < 60) {
      ctx.addIssue({
        path: ["end_time"],
        message: "End time must be at least 1 hour after start time.",
        code: z.ZodIssueCode.custom,
      });
    }
  });


export default ShiftSchema;
