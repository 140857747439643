import React, { useState, useEffect } from "react";
import {
  Dialog,
  FormControl,
  FormLabel,
  TextField,
  MenuItem,
  Select,
  Button,
  IconButton,
  SvgIcon,
  ListItemText,
  Box, FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import DateInput from "../../DateTimePicker";
import "./AddOrUpdateCustomer.css";
import Modal from "../../Modal";
import { WingoInput } from "../../Inputs/CustomInputs";
import "./../../../../blocks/addressmanagement/src/Address.css"
import PhoneInputComponent from "../../PhoneInput";

interface CustomerData { // Stores the form data for the new customer
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string,
  phoneNumber: string;
  date_of_birth: string;
  gender: string | number;
  address: {

    city:string;
    country: string;
    address: string;
    postCode: string;
    addressDirection: string;
    defaultAddress: boolean;
  };
}

interface AddCustomerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: CustomerData) => void;
  // initialCustomerData?: CustomerData | null;
  initialCustomerData?: CustomerData | null;

  isEditing?: boolean;
  // validationErrors?: Partial<Record<keyof CustomerData, string>>;
  // validationErrors?: Partial<Record<keyof CustomerData, any>>;
  validationErrors?: any;


}

const AddOrUpdateCustomer: React.FC<AddCustomerProps> = ({
  open,
  onClose,
  onSubmit,
  initialCustomerData = null,
  isEditing = false,
  validationErrors = {},
}) => {
  const defaultFormData: CustomerData = {
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    countryCode: "44",
    phoneNumber: "",
    date_of_birth: "",

    address: {
    city:"ranchi",

      country: "UK",
      address: "",
      postCode: '',
      addressDirection: "",
      defaultAddress: true,
    },
  }

  const [formData, setFormData] = useState<CustomerData>(defaultFormData);
  const [dummyAddressList, setDummyAddressList] = useState([
    { id: 1, type: "Home", address: "123 Main Street, New York", city: "New York", postCode: "NY10001" },
    // { id: 2, type: "Work", address: "456 Elm Street, San Francisco", city: "San Francisco", postCode: "SF20002" },
    // { id: 3, type: "Other", address: "789 Pine Street, Seattle", city: "Seattle", postCode: "WA30003" },
  ]);
  const [selectedAddress, setSelectedAddress] = useState<any | null>(null);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (isEditing && initialCustomerData) {
      setFormData({
        ...initialCustomerData,
        phoneNumber: String( `${initialCustomerData.countryCode || '44'}${initialCustomerData.phoneNumber}`), // Ensure phoneNumber is a string
        gender: initialCustomerData.gender == "male"? 0 : initialCustomerData.gender == "female" ? 1 : 3,
        date_of_birth: initialCustomerData.date_of_birth
      });
    } else {
      setFormData(defaultFormData);
    }
  }, [initialCustomerData, isEditing]);
  

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> |  SelectChangeEvent<string | null>
  ) => {
    const { name, value } = e.target;
    if(name == 'postCode' || name == 'addressDirection'){
      setFormData({ ...formData, address:{...formData.address, [name]: value} });

    }else{
      setFormData({ ...formData, [name]: value });

    }
  };




  const handleDateChange = (date: Date | string | null) => {
    if (date) {
      if (typeof date === "string") {
        let value = date.replace(/\D/g, "");
        if (value.length > 4) value = value.slice(0, 4) + "-" + value.slice(4);
        if (value.length > 7) value = value.slice(0, 7) + "-" + value.slice(7, 9);
        setFormData({ ...formData, date_of_birth: value });
    }

      if (date instanceof Date) {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setFormData({ ...formData, date_of_birth: formattedDate });
      };
    };
  };

  const handleEditAddress = (address: any) => {
    setSelectedAddress(address);
    setIsEditingAddress(true);
    setShowEditModal(true);
  };
  
  const handleSaveAddress = (updatedAddress: any) => {
    setDummyAddressList((prev) =>
      prev.map((addr) => (addr.id === updatedAddress.id ? updatedAddress : addr))
    );
    setShowEditModal(false);
    setSelectedAddress(null);
  };

  const handleDeleteAddress = (address: any) => {
    setSelectedAddress(address);
    setShowDeleteModal(true);
  };
  
  const confirmDeleteAddress = () => {
    setDummyAddressList((prev) => prev.filter((addr) => addr.id !== selectedAddress.id));
    setShowDeleteModal(false);
    setSelectedAddress(null);
  };
  

 
  const handleSubmit = () => {
    onSubmit(formData);
  };

  const handleReset = () => {
    setFormData(defaultFormData);
  };

  return (
    <>
    <Modal
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <div className="add_new_staff__dialog__header">
        <h2>{!isEditing ?  `Add New Customer` : `Update Customer Information`}</h2>
        <IconButton onClick={onClose}>
          <SvgIcon>
            <svg
              width="800"
              height="800"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414"
                fill="#0D0D0D"
              />
            </svg>
          </SvgIcon>
        </IconButton>
      </div>
      <div className="add_new_staff__dialog__content">
        { (
          <div className="add_new_staff__form__grid">
            
            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                First Name
              </FormLabel>

              <WingoInput
                name="firstName"
                placeholder="Enter the first name"
                value={formData.firstName}
                onChange={handleOnChange}
                error={!!validationErrors["firstName"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["firstName"]}
              >
                {validationErrors["firstName"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Last Name
              </FormLabel>

              <WingoInput
                name="lastName"
                placeholder="Enter the last name"
                value={formData.lastName}
                onChange={handleOnChange}
                error={!!validationErrors["lastName"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["lastName"]}
              >
                {validationErrors["lastName"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Email
              </FormLabel>

              <WingoInput
                name="email"
                placeholder="Enter the email"
                value={formData.email}
                onChange={handleOnChange}
                error={!!validationErrors["email"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["email"]}
              >
                {validationErrors["email"]}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Mobile Number
              </FormLabel>

              <PhoneInputComponent
                inputStyle={{
                  fontWeight: 400,
                  color: "#1D1D1D",
                  fontFamily: "Barmeno",
                  width: "100%",
                  height: "46px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1D1D1D",
                  margin:"0px",
                }}
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                }}
                country={"gb"}
                value={formData?.phoneNumber || ''}
                onChange={(value, data) => {
                  if ("dialCode" in data) {
                    // setFormData({ ...formData, });
                    setFormData({ ...formData, countryCode: data.dialCode, phoneNumber: value });

                  }
                  // setFormData({ ...formData, phoneNumber: value });
                }}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["phoneNumber"]}
              >
                {validationErrors["phoneNumber"]}
              </FormHelperText>
            </FormControl>

            


               <FormControl>
                  <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                   DOB(optional)              
               </FormLabel>
                  <DateInput
                    onChange={handleDateChange}
                    onChangeRaw={(e) => handleDateChange(e.target.value)}
                    placeholderText="YYYY-MM-DD"
                    value={formData.date_of_birth}
                    maxDate={new Date()}
                  />
                  <FormHelperText
                    classes={{ root: "add_new_staff__helper_text" }}
                    error={!!validationErrors["date_of_birth"]}
                  >
                    {validationErrors["date_of_birth"]}
                  </FormHelperText>
                </FormControl>

            <FormControl>
              <FormLabel classes={{  root: "add_new_staff__label" }}>
                Gender (optional)
              </FormLabel>

              <Select
                name="gender"
                value={`${formData.gender}`}
                onChange={handleOnChange}
                displayEmpty
                error={!!validationErrors["gender"]}
                classes={{ root: "add_new_staff__select" }} // Add custom classes if needed
              >
                {/* <MenuItem value="" disabled >
                  Select gender
                </MenuItem> */}
                <MenuItem value={0} classes={{ root: "add_new_staff__select" }}>Male</MenuItem>
                <MenuItem value={1} classes={{ root: "add_new_staff__select" }}>Female</MenuItem>
                <MenuItem value={3} classes={{ root: "add_new_staff__select" }}>I prefer not to say</MenuItem>
              </Select>

              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["gender"]}
              >
                {validationErrors["gender"]}
              </FormHelperText>
            </FormControl>
           </div>
        )}

        {(
          <div className="add_new_staff__form__grid">
            <FormControl required
              className="add_new_staff__span"
            >
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Enter your Postcode
              </FormLabel>
              <WingoInput
                name="postCode"
                type="postCode"
                placeholder="Enter the postCode"
                value={formData.address.postCode}
                onChange={handleOnChange}
                error={!!validationErrors["postCode"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["postCode"]}
              >
                {validationErrors["postCode"]}
              </FormHelperText>
            </FormControl>

            </div>
        )}

          <div className="add_new_staff__form__grid">
            {/* <FormControl
              className="add_new_staff__span"
            >
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Address Directions
              </FormLabel>
              <WingoInput
                name="addressDirection"
                type="addressDirection"
                placeholder="Enter the address direction"
                value={formData.address.addressDirection}
                onChange={handleOnChange}
                error={!!validationErrors["addressDirection"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["addressDirection"]}
              >
                {validationErrors["addressDirection"]}
              </FormHelperText>
            </FormControl> */}
            <FormControl className="add_new_staff__span">
              <FormLabel
                classes={{
                  asterisk: "add_new_staff__asterisk",
                  root: "add_new_staff__label",
                }}
              >
                Address Directions
              </FormLabel>
              <TextField
                name="addressDirection"
                type="text"
                placeholder="Enter the address direction"
                value={formData.address.addressDirection}
                onChange={(e) => {
                  // if (e.target.value.length <= 100) {
                    handleOnChange(e); 
                  // }
                }}
                error={!!validationErrors["address"]}
                helperText={
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ color: "red" }}>{validationErrors["address"]}</span>
                    <span style={{ textAlign: "right" }}>
                      {`${formData.address.addressDirection.length}/100`}
                    </span>
                  </div>
                }
                FormHelperTextProps={{
                  classes: { root: "add_new_staff__helper_text" },
                }}
                multiline
                rows={2} 
                // InputProps={{
                //   endAdornment: (
                //     <Box className="character-counter" sx={{ mt: 1, fontSize: "12px", textAlign: "right", width: "100%" }}>
                //       {`${formData.address.addressDirection.length}/100`}
                //     </Box>
                //   ),
                // }}
              />
            </FormControl>

            </div>

        {/* <div className="address_list">
          <FormLabel classes={{ root: "add_new_staff__label" }}>Address List</FormLabel>
          {dummyAddressList.map((address) => (
            <div key={address.id} className="address_item">
              <ListItemText
                primary={`${address.type}: ${address.address}, ${address.city}, ${address.postCode}`}
                classes={{ primary: "address_text" }}
              />
              <div className="address_actions">
                <IconButton onClick={() => handleEditAddress(address)}>
                  <SvgIcon>
                    {/* Edit Icon */}
                    {/* <path d="M12 2c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zm-5 15.7c.3-.3.8-.3 1.1 0l1.4 1.4 3-3c.4-.4.9-.4 1.3 0 .4.4.4.9 0 1.3l-3 3 1.4 1.4c.3.3.3.8 0 1.1l-5 5c-.3.3-.8.3-1.1 0l-5-5c-.3-.3-.3-.8 0-1.1l5-5zm15-3v6h-6v-6h-2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-6h-2z"></path> */}
                  {/* </SvgIcon> */}
                {/* </IconButton> */}
                {/* <IconButton onClick={() => handleDeleteAddress(address)}>
                  <SvgIcon> */}
                    
                    {/* <path d="M19 4h-3.5l-1-1h-5l-1 1h-3.5c-.5 0-.9.4-.9.9v1.1h18v-1.1c0-.5-.4-.9-.9-.9zm-3 15c0 1.1-.9 2-2 2h-6c-1.1 0-2-.9-2-2v-10h10v10zm-4-8c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1s1-.4 1-1v-5c0-.6-.4-1-1-1zm-3 0c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1s1-.4 1-1v-5c0-.6-.4-1-1-1zm6 0c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1s1-.4 1-1v-5c0-.6-.4-1-1-1z"></path>
                  </SvgIcon>
                </IconButton>
              </div>
            </div>
          ))}
        </div> */}

        

        <div className="add_new_staff__submit_group">
          <Button
            variant="outlined"
            classes={{
              root: "add_new_staff__reset__button",
            }}
            onClick={handleReset}
          >
            CANCEL
          </Button>

          <Button
            variant="contained"
            classes={{
              root: "add_new_staff__submit__button",
            }}
            onClick={handleSubmit}
          >
            {!isEditing ? "ADD CUSTOMER" : "UPDATE"}
          </Button>
        </div>
      </div>
    </Modal>
    <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
    <div className="edit_address_modal">
      <h3>Edit Address</h3>
      <TextField
        label="Address"
        value={selectedAddress?.address || ""}
        onChange={(e) => setSelectedAddress({ ...selectedAddress, address: e.target.value })}
        fullWidth
      />
      <TextField
        label="City"
        value={selectedAddress?.city || ""}
        onChange={(e) => setSelectedAddress({ ...selectedAddress, city: e.target.value })}
        fullWidth
      />
      <TextField
        label="Post Code"
        value={selectedAddress?.postCode || ""}
        onChange={(e) => setSelectedAddress({ ...selectedAddress, postCode: e.target.value })}
        fullWidth
      />
      <div className="modal_actions">
        <Button onClick={() => setShowEditModal(false)}>Cancel</Button>
        <Button variant="contained" onClick={() => handleSaveAddress(selectedAddress)}>Save</Button>
      </div>
    </div>
  </Modal>
  <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
  <div className="delete_confirmation_modal">
    <h3>Delete Address</h3>
    <p>Are you sure you want to delete this address?</p>
    <div className="modal_actions">
      <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
      <Button variant="contained" color="secondary" onClick={confirmDeleteAddress}>Delete</Button>
    </div>
  </div>
</Modal>

  </>
  );
};

export default AddOrUpdateCustomer;


















