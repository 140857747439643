import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { imgVisbility, imgVisbilityOff, mailSupport, phoneSupport } from "./assets";
import {
 SupportCategory
} from "./Cfpossupportpage1Controller";

// Customizable Area End

import Cfpossupportpage1Controller, {
  Props,
  configJSON,
} from "./Cfpossupportpage1Controller";
import DashboardWrapper from "../../../components/src/Dashboard/DashboardWrapper";
import Header from "../../../components/src/Dashboard/Header/Header";
import "./SupportStyle.css"
import { ChevronRightRounded, SearchRounded,ExpandMore } from "@material-ui/icons";
import { BaseInput } from "../../../components/src/Inputs/CustomInputs";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

export default class Cfpossupportpage1 extends Cfpossupportpage1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  displayCardSupport = (supportCategory: SupportCategory[]) => {
    return supportCategory.map((value) => (
      <div className="supportCardContainer" onClick={()=>{this.getSupportFaqCategoryWise(value.id,value.attributes.title,value.attributes.description,value.attributes.support_contact_infos)}} >
        <div className="iconTitleContentContainer">
          <img style={{ width: "40px", height: "40px" }} src={value.attributes.icon.url} />
          <div className="titleDescriptionContainer">
            <div className="titleSupport">{value.attributes.title}</div>
            <div className="descriptionSupport">{value.attributes.description}</div>
          </div>
        </div>
      </div>
    ))
  }
  searchStaff = (placeholder:string) => {
    return (
      <div
        data-testid="search"
        className="search__wrapper"
        // onClick={this.handleFocus}
      >
        <SearchRounded fontSize="small" />
        <BaseInput
          // inputRef={this.searchRef}
          placeholder={placeholder}
          value={this.state.searchInput}
          onChange={this.handleSearchQuery}
          data-test-id={"searchInput"}
          onKeyPress={this.pressEnterToSearch}
        />
      </div>
    );
  };
  getContactSupport=()=>{
    return(
<div className="supportContactContainer">
          <div style={{display: "flex", flexDirection: "column",gap: "12px"}}>
            <div className="titleQuestionSupport" style={{textAlign:'start'}}>Didn’t find an answer?</div>
            <div className="descriptionQuestionSupport" style={{textAlign:'start'}}>Contact the rightful person to get the issues resolved.</div>
          </div>

          <div className="supportPersonInfoContainer">
          <div style={{width:"100%",maxWidth:"338px",color:"#404040"}}>{this.state.title} <span style={{color:"#1d1d1d"}}>- {this.state.supportContactList[0].name}</span></div>
          <div className="contactSupport"> <div style={{padding:"4px", width:"fit-content"}}><img src={phoneSupport} style={{width:"24px", height:"24px"}} /></div>{this.state.supportContactList[0].phone}</div>
          <div className="contactSupport"> <div style={{padding:"4px", width:"fit-content"}}><img src={mailSupport} style={{width:"24px", height:"24px"}} /></div>{this.state.supportContactList[0].email}</div>
          </div>
        
        </div>
    )
  }

  categoryWiseSupport = () => {
    return (
      <div className="cfsupportmodalSupport" style={{ maxWidth: "1192px", width: "calc(100% - 24px)" }} >
        <div className="supportallcontentcontainer">
          <div className="supportInDepthHeading"> <span style={{ color: "#747474", cursor:"pointer" }} onClick={this.closeCategoryWiseView} >{`Support`}</span><ChevronRightRounded /><span>{`${this.state.title}`}</span> </div>
          <div className="CategoryHeadingSearchContainer">
            <div className="CategoryHeadingContainer">
              <div className="CategoryHeading">{this.state.title}</div>
              <div className="CategoryDescription">{this.state.description}</div>
            </div>
            <div className="categorySearchContainer">
              {this.searchStaff(`Search on ${this.state.title}`)}
            </div>
          </div>
          <div>
            
            { this.state.categorywise_faq_questions.length>0?
            this.state.categorywise_faq_questions.map((value) => (
              <Accordion
                key={value.id}
                style={{ borderRadius: "8px", margin: "12px 0px", fontWeight: 600, fontSize: "16px", color: "#313131" }}
                expanded={this.state.expanded == `panel${value.id}`}
                onChange={this.handleExpand(`panel${value.id}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{
                    fontFamily: "Barmeno",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#313131"
                  }}
                >
                  {value.attributes.question}
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    fontFamily: "Barmeno",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#313131",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px"
                  }}
                >
                  {value.attributes.answer}
                  {value.attributes.image && <img src={value.attributes.image.url} alt={value.attributes.image.file_name} />}
                </AccordionDetails>
              </Accordion>
            )):
            <div className="noResultFound">
              No Result Found
              </div>}

          </div>
        </div>

        {this.state.supportContactList.length > 0 && this.getContactSupport()}
      </div>
    )
  }

  categoryAllSupportDisplay = () => {
    return (
      <div className="cfsupportmodalSupport" style={{ maxWidth: "1192px", width: "calc(100% - 24px)" }} >
        <div className="SupportContentSearchContainer">
          <div className="wingoHelpSearchHeadingContainer">
            <div className="Wingo-help-Heading">
              <div className="helpCenterHeading">Wingo’s Help Center</div>
              <div className="helpCenterHeadingContent">Detailed solutions to help you manage the restaurant efficiently.</div>
            </div>
            <div className="helpSearchContainer">
              {this.searchStaff(`How can we help you?`)}
            </div>
          </div>
          <div className="scrollable_container">
            <div className="supportCardContainercontainer">
              {this.displayCardSupport(this.state.support_category_list)}
            </div>
          </div>
        </div>
        <div>
          <div className="titleQuestionSupport" >Didn’t find an answer?</div>
          <div className="descriptionQuestionSupport">Contact the rightful person to get the issues resolved.</div>
        </div>
      </div>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <DashboardWrapper navigation={this.props.navigation}>
          <div className="header-container">
            <Header
              navigation={this.props.navigation}
              headerTitle="Contact  & Support"
            />
          </div>
          {this.state.categoryWiseId ? this.categoryWiseSupport():this.categoryAllSupportDisplay()}

        </DashboardWrapper>

      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End
