import React, { useState, useEffect } from "react";
import {
  Dialog,
  FormControl,
  FormLabel,
  TextField,
  MenuItem,
  Select,
  Button,
  IconButton,
  SvgIcon,
  ListItemText,
  Box, FormHelperText,
  Tabs,
  Tab,
} from "@mui/material";
import DateInput from "../../DateTimePicker";
import "./CustomerMarketingInfo.css";
import Modal from "../../Modal";
import { WingoInput } from "../../Inputs/CustomInputs";
// import {   } from "@material-ui/core";
import PhoneInputComponent from "../../PhoneInput";
import CustomGreenCheckbox from "../../CustomGreenCheckBox.web";
import { WingosChip } from "../../Chip";
// import { Tab } from "react-native-elements";

interface CustomerData { // Stores the form data for the new customer
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  date_of_birth: string;
  gender: string | number;
  address: {

    city:string;
    country: string;
    address: string;
    postCode: string;
    addressDirection: string;
    defaultAddress: boolean;
  };
  email_communication?: boolean,
  mobile_communication?: boolean,
}

interface AddCustomerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (body: any) => void;
  // initialCustomerData?: CustomerData | null;
  initialCustomerData?: CustomerData | null;

  isEditing?: boolean;
  // validationErrors?: Partial<Record<keyof CustomerData, string>>;
  validationErrors?: Partial<Record<keyof CustomerData, any>>;

}

const CustomerMarketingInfo: React.FC<AddCustomerProps> = ({
  open,
  onClose,
  onSubmit,
  initialCustomerData = null,
  isEditing = true,
  validationErrors = {},
}) => {
  const defaultFormData: CustomerData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    date_of_birth: "",
    gender:"",
    address: {
    city:"ranchi",

      country: "UK",
      address: "",
      postCode: "",
      addressDirection: "test",
      defaultAddress: true,
    },
    email_communication: initialCustomerData?.email_communication || false,
  mobile_communication: initialCustomerData?.mobile_communication || false,
  }

  const [formData, setFormData] = useState<CustomerData>(defaultFormData);
console.log('initialCustomerData',initialCustomerData);
  useEffect(() => {
    if (initialCustomerData) {
      setFormData({
        ...initialCustomerData,
        phoneNumber: initialCustomerData.phoneNumber || "",
        email: initialCustomerData.email || "",
      });
      setEmailCommunication(initialCustomerData.email_communication || false);
      setMobileCommunication(initialCustomerData.mobile_communication || false)
    } 
    // else {
    //   setFormData(defaultFormData);
    // }

  }, [initialCustomerData]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  

  const [emailCommunication, setEmailCommunication] = useState<boolean>(initialCustomerData?.email_communication || false); // Default is "Opt in"
  const [mobileCommunication, setMobileCommunication] = useState<boolean>(initialCustomerData?.mobile_communication || false); // Default is "Opt in"

  const handleTabChange = (type: string, value: boolean) => {
    if (type === "email") setEmailCommunication(value);
    if (type === "mobile") setMobileCommunication(value);
  };

  const handleSubmit = () => {
    const body = {
      email_communication: emailCommunication,
      mobile_communication: mobileCommunication,
    };

    if (initialCustomerData) {
      onSubmit({
        body
      });
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      // Format the date as "YYYY-MM-DD"
      const formattedDate = date.toISOString().split("T")[0];
      setFormData({ ...formData, date_of_birth: formattedDate });
    } else {
      setFormData({ ...formData, date_of_birth: "" }); // Handle case where date is cleared
    }
  };

//   const handleSubmit = () => {
//     onSubmit(formData);
//   };

  const handleReset = () => {
    setFormData(defaultFormData);
  };

  return (
    <Modal
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <div className="add_new_staff__dialog__header">
        <h2>Marketing Information</h2>
        <IconButton onClick={onClose}>
          <SvgIcon>
            <svg
              width="800"
              height="800"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414"
                fill="#0D0D0D"
              />
            </svg>
          </SvgIcon>
        </IconButton>
      </div>
      <div className="add_new_staff__dialog__content">
        { (
          <div className="add_new_staff__form__grid">
            

            </div>
        )}

        {(
          <div className="add_new_staff__form__grid">
            <FormControl
              required
              className="add_new_staff__span"
            >
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Email Address
              </FormLabel>
              <WingoInput
                name="email"
                type="email"
                placeholder="Enter the e-mail address"
                value={formData.email}
                onChange={handleOnChange}
                error={!!validationErrors["email"]}
                disabled={true}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["email"]}
              >
                {validationErrors["email"]}
              </FormHelperText>
            </FormControl>

            
          </div>
        )}
        <Tabs
                value={emailCommunication ? 0 : 1}
                onChange={(e, value) => handleTabChange("email", value === 0)}
                classes={{
                  root: "staffInformation__tab__wrapper",
                  indicator: "staffInformation__tab__hidden_indicator",
                }}
              >
                <Tab
                  label="Opt in"
                  classes={{ root: "staffInformation__tab", selected: "staffInformation__tab__selected" }}
                />
                <Tab
                  label="Opt out"
                  classes={{ root: "staffInformation__tab", selected: "staffInformation__tab__selected" }}
                />
              </Tabs>
              {(
          <div className="add_new_staff__form__grid">
            <FormControl
              required
              className="add_new_staff__span"
            >
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Mobile Number
              </FormLabel>
              <WingoInput
                name="phoneNumber"
                type="text"
                placeholder="Enter the e-mail address"
                value={formData.phoneNumber}
                onChange={handleOnChange}
                error={!!validationErrors["phoneNumber"]}
                disabled={true}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["phoneNumber"]}
              >
                {validationErrors["phoneNumber"]}
              </FormHelperText>
            </FormControl>
          </div>
        )}
        <Tabs
                value={mobileCommunication ? 0 : 1}
                onChange={(e, value) => handleTabChange("mobile", value === 0)}
                classes={{
                  root: "staffInformation__tab__wrapper",
                  indicator: "staffInformation__tab__hidden_indicator",
                }}
              >
                <Tab
                  label="Opt in"
                  classes={{ root: "staffInformation__tab", selected: "staffInformation__tab__selected" }}
                />
                <Tab
                  label="Opt out"
                  classes={{ root: "staffInformation__tab", selected: "staffInformation__tab__selected" }}
                />
              </Tabs>

          {/* <div className="add_new_staff__form__grid">
            <FormControl
              required
              className="add_new_staff__span"
            >
              <FormLabel classes={{ asterisk: "add_new_staff__asterisk", root: "add_new_staff__label" }}>
                Address Directions
              </FormLabel>
              <WingoInput
                name="email"
                type="email"
                placeholder="Enter the e-mail address"
                value={formData.email}
                onChange={handleOnChange}
                error={!!validationErrors["email"]}
              />
              <FormHelperText
                classes={{ root: "add_new_staff__helper_text" }}
                error={!!validationErrors["email"]}
              >
                {validationErrors["email"]}
              </FormHelperText>
            </FormControl>
            </div> */}

        

        <div className="add_new_staff__submit_group">
          <Button
            variant="outlined"
            classes={{
              root: "add_new_staff__reset__button",
            }}
            onClick={handleReset}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            classes={{
              root: "add_new_staff__submit__button",
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerMarketingInfo;