Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfposcustomermanagementfe";
exports.labelBodyText = "cfposcustomermanagementfe Body";

exports.btnExampleTitle = "CLICK ME";
exports.customerList = {
  method: "GET",
  endPoint: "bx_block_cfposcustomermanagementfe/customers",
  contentType: "application/json"
};

exports.customerMarketInfo = {
 
  endpoints: {
    updateMarketingInfo: (customerId) => `bx_block_cfposcustomermanagementfe/customers/${customerId}/marketing_communication_permission`,
  },
  headers: (token) => ({
    "Content-Type": "application/json",
    token: token,
  }),
};
// Customizable Area End