import React from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import Chip from "../components/Chip"; // Reuse the Chip component for styled labels

export type Order = {
  id: string;
  order_number: string;
  date_time: string;
  store: string;
  order_type: string;
  amount: string;
  payment_type: string;
  status: string;
  delivery_addresses:any;
};

interface Props {
  actionCell: (info: CellContext<Order, any>) => JSX.Element;
}

export const CustomerOrdersColumns = ({ actionCell }: Props) => {
  const columns: ColumnDef<Order, any>[] = [
    {
      header: "Order Number",
      accessorKey: "order_number", 
      cell: (info) => <span>{info.getValue() || "N/A"}</span>,
    },
    // {
    //   header: "Date & Time",
    //   accessorKey: "placed_at", 
    //   cell: (info) => {
    //     const value = info.getValue();
    //     if (!value) return <span>N/A</span>; 
    //     const [date, time] = value.split("T");
    //     return (
    //       <div>
    //         <span>{date}</span>
    //         <br />
    //         <span style={{ color: "green", fontSize: "0.9em" }}>
    //           {time.split("+")[0]}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      header: "Date & Time",
      accessorKey: "placed_at", 
      cell: (info) => {
        const value = info.getValue();
        if (!value) return <span>N/A</span>;
    
        const dateObject = new Date(value);
    
        
        const formattedDate = dateObject.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
    
        
        const formattedTime = dateObject.toLocaleTimeString("en-GB", { hour12: false });
    
        return (
          <div>
            <span>{formattedDate}</span>
            <br />
            <span style={{ color: "green", fontSize: "0.9em" }}>
              {formattedTime}
            </span>
          </div>
        );
      },
    },
    {
      header: "Store",
      accessorKey: "restaurant", 
      cell: (info) => {
        const deliveryAddresses = info.getValue();
        return <span>{deliveryAddresses}</span>;
      },
    },
    
    
    {
      header: "Order Type",
      accessorKey: "order_type", 
      cell: (info) => {
        const type = info.getValue();
        switch (type?.toLowerCase()) {
          case "delivery":
            return <span>{"Delivery"}</span>;
          case "collection":
            // return <Chip label="Collection" style={{ backgroundColor: "#FEF3C7" }} />;
            return <span>{"Collection"}</span>;

          default:
            return <Chip label={type || "N/A"} />;
        }
      },
    },
    {
      header: "Amount",
      accessorKey: "total", 
      cell: (info) => <span>£{info.getValue() || "0.00"}</span>,
    },
    {
      header: "Payment Type",
      accessorKey: "source", 
      cell: (info) => {
      const status = info.getValue();
              return <span>{status}</span>;
        }
        
          
    },
    {
      header: "Status",
      accessorKey: "status", 
      cell: (info) => {
        const status = info.getValue();
        switch (status?.toLowerCase()) {
          case "in_transit":
            return <Chip label="PLACED" style={{ backgroundColor: "#FEF3C7", color: "#D97706" }} />;
          case "cooking":
            return <Chip label="COOKING " style={{ backgroundColor: "#D1FAE5", color: "#059669" }} />;
          case "confirmed":
            return <Chip label="CONFIRMED " style={{ backgroundColor: "#D1FAE5", color: "#059669" }} />;
          case "ready":
            return <Chip label="READY" style={{ backgroundColor: "#D1FAE5", color: "#059669" }} />;
          case "delivered":
            return <Chip label="DELIVERED" style={{ backgroundColor: "#D1FAE5", color: "#059669" }} />;
          case "collected ":
            return <Chip label="COLLECTED" style={{ backgroundColor: "#D1FAE5", color: "#059669" }} />;
            case "payment_failed  ":
              return <Chip label="PAYMENT FAILED" style={{ backgroundColor: "#D1FAE5", color: "#059669" }} />;
              case "payment_pending":
                return <Chip label="PAYMENT PENDING" style={{ backgroundColor: "#D1FAE5", color: "#059669" }} />;
                case "cancelled_and_refunded":
                  return <Chip label="CANCELLED AND REFUNDED" style={{ backgroundColor: "#D1FAE5", color: "#059669" }} />;
          default:
            return <Chip label={status?.toUpperCase() || "Unknown"} />;
        }
      },
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (info) => actionCell(info), 
    },
  ];

  return columns;
};
