import { CellContext, ColumnDef } from "@tanstack/react-table";

interface CustomerAttributes {
  id: string;
  customer_id: string;
  name: string;
  email: string;
  phone_number: string;
  postcode: string;
}

interface Props<T> {
  actionCell: (info: CellContext<T, any>) => JSX.Element;
}
export const customerInformationColumns = ({
  actionCell,
}: Props<CustomerAttributes>) => {
  const columns: ColumnDef<CustomerAttributes, any>[] = [
    {
      header: "Customer ID",
      accessorKey: "customerID",
      cell: (info) => info.getValue(),
    },
    {
      header: "Customer Name",
      accessorKey: "customerName",
      cell: (info) => info.getValue(),
    },
    {
      header: "Email Address",
      accessorKey: "email",
      cell: (info) => info.getValue(),
    },
    {
      header: "Mobile Number",
      accessorKey: "phoneNumber",
      cell: (info) => info.getValue(),
    },
    {
      header: "Postcode",
      accessorKey: "postcode",
      cell: (info) => info.getValue(),
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (info) => actionCell(info),
    },
  ];

  return columns;
};
