import React from "react";
import moment from "moment";
import { FormControl, FormHelperText, IconButton } from "@mui/material";
import { CloseRounded } from "@material-ui/icons";
import { ContainedButton, OutlinedButton } from "../../../Button";
import { WingoInput } from "../../../Inputs/CustomInputs";
import { WingosDropzone, WingosPreview } from "../../../Dropzone";
import { TimeOffSchema } from "../../../Schemas/TimeOffSchema";
import Modal from "../../../Modal";
import FormLabel from "../../../FormLabel";
import DateInput from "../../../DateTimePicker";
import { convertToFormData } from "../../../utils";
import "./TimeOff.css";

interface Props {
  selectedStaff: number;
  timeOff: (id: number, data: FormData) => void;
};

const TimeOff = ({ selectedStaff, timeOff }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<{
    start_date: string;
    end_date: string;
    description: string;
    attachment: File[];
  }>({
    start_date: "",
    end_date: "",
    description: "",
    attachment: []
  });
  const [errors, setErrors] = React.useState<{ message: string; path: (number | string)[] }[]>([]);

  const onClose = () => {
    setOpen(false);
    setData({
      start_date: "",
      end_date: "",
      description: "",
      attachment: []
    });
    setErrors([]);
  };

  const onOpen = () =>
    setOpen(true);

  const handleDateChange = (date: Date | string | null, label: string) => {
    if (date) {
      if (typeof date === "string") {
        let value = date.replace(/\D/g, "");
        if (value.length > 4) value = value.slice(0, 4) + "-" + value.slice(4);
        if (value.length > 7) value = value.slice(0, 7) + "-" + value.slice(7, 9);
        setData({ ...data, [label]: value });
        return value;
    }

      if (date instanceof Date) {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setData({ ...data, [label]: formattedDate });
        return formattedDate;
      };
    };
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value }= event.target;
    setData({
      ...data,
      [name]: value
    })
  };

  const removeFile = (file: File) => {
    setData({
      ...data,
      attachment: data.attachment.filter((f) => {
        const fileName = f.name;
        const targetFileName = file.name;
        return fileName !== targetFileName;
      })
    });
  };

  const submit = () => {
    const { success, error } = TimeOffSchema.safeParse(data);

    if (!success) {
      const extractedErrors = error?.errors?.map((error) => ({
        message: error.message,
        path: error.path,
      }));
      extractedErrors && setErrors(extractedErrors);
    } else {
      setErrors([]);
      timeOff(selectedStaff, convertToFormData(data, "time_off"));
      onClose();
    }
  };

  const getError = (field: string) => errors.find(value => value.path[0] === field);

  return (
    <>
      <OutlinedButton className="time_off__button" onClick={onOpen}>Time Off</OutlinedButton>
      <Modal
        open={open}
        onClose={onClose}
      >
        <div className="time_off__wrapper">
          <div className="time_off__header">
            <h1>Time Off</h1>

            <IconButton onClick={onClose}>
              <CloseRounded />
            </IconButton>
          </div>

          <div className="time_off__body">
            <div className="time_off__date_inputs">
              <FormControl fullWidth required>
                <FormLabel>Start Date</FormLabel>
                <DateInput
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-DD"
                  minDate={moment().toDate()}
                  value={data.start_date}
                  onChange={(date) => handleDateChange(date, "start_date")}
                />
                <FormHelperText className="time_off__helper_text" data-error={!!getError("start_date")}>
                  {getError("start_date")?.message}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth required>
                <FormLabel>End Date</FormLabel>
                <DateInput
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-DD"
                  minDate={moment(data.start_date).toDate()}
                  value={data.end_date}
                  onChange={(date) => handleDateChange(date, "end_date")}
                />
                <FormHelperText className="time_off__helper_text" data-error={!!getError("end_date")}>
                  {getError("end_date")?.message}
                </FormHelperText>
              </FormControl>
            </div>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <WingoInput
                name="description"
                multiline
                placeholder="Enter the description"
                rows={5}
                value={data.description}
                onChange={handleInputChange}
              />
              <FormHelperText className="time_off__helper_text" data-error={!!getError("description") || data.description.length > 25}>
                <span>{getError("description")?.message}</span>
                <span>{data.description.length}/25</span>
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Attachment</FormLabel>
              {data.attachment.length > 0 ? (
                <WingosPreview
                  files={data.attachment}
                  removeFile={() => removeFile(data.attachment[0])}
                />
              ) : (
                <WingosDropzone
                  maxFiles={1}
                  onDropAccepted={(acceptedFiles) => {
                    setData({
                      ...data,
                      attachment: acceptedFiles,
                    });
                  }}
                />
              )}
              <FormHelperText className="time_off__helper_text" data-error={!!getError("attachment")}>
                {getError("attachment")?.message}
              </FormHelperText>
            </FormControl>
          </div>

          <div className="time_off__footer">
            <OutlinedButton onClick={onClose}>CANCEL</OutlinedButton>
            <ContainedButton onClick={submit}>APPLY</ContainedButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TimeOff;
