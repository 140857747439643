import { z } from "zod";
import moment from "moment";

export const TimeOffSchema = z.object({
  start_date: z.string().refine((value) => moment(value, 'YYYY-MM-DD').isValid(), {
    message: "Invalid date format.",
  }),
  end_date: z.string().refine((value) => moment(value, 'YYYY-MM-DD').isValid(), {
    message: "Invalid date format.",
  }),
  description: z
    .string()
    .optional()
    .refine((value) => !value || value.length <= 25, {
      message: "Description must not exceed 25 characters.",
    }),
  attachment: z
    .array(
      z.instanceof(File).refine((file) => {
        const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
        return validTypes.includes(file.type);
      }, {
        message: "File must be a PDF or an image (JPEG/PNG).",
      })
    )
    .refine((files) => files.length === 0 || files.length === 1, {
      message: "Attachment must contain exactly one file.",
    }),
}).superRefine((data, ctx) => {
  const startDate = moment(data.start_date, 'YYYY-MM-DD');
  if (data.start_date) {
    const endDate = moment(data.end_date, 'YYYY-MM-DD');
    if (!endDate.isSameOrAfter(startDate)) {
      ctx.addIssue({
        path: ['end_date'],
        message: 'End date must not be before start date.',
        code: z.ZodIssueCode.custom,
      });
    }
  }
});
