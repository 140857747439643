import React from "react";
import { ZodIssue } from "zod";
import { FormControl, FormHelperText } from "@mui/material";
import Modal from "../../../Modal";
import { ContainedButton, OutlinedButton } from "../../../Button";
import { FlagReasonSchema } from "../../../Schemas/FlagReasonSchema";
import { WingoInput } from "../../../Inputs/CustomInputs";
import { flaggedArea } from "../../../../../blocks/dashboard/src/assets";
import { DeliveryArea } from "../../../../../blocks/cfdeliveryareamanagement1/src/types";
import "./FlagAreaPopup.css";

interface Props {
  selectedAddress: DeliveryArea;
  open: boolean;
  onClose: () => void;
  submit: (id: number, flag_reason: string) => void;
};

const FlagAreaPopup = ({ selectedAddress, open, onClose, submit }: Props) => {
  const { id, flagged } = selectedAddress;
  const [flagReason, setFlagReason] = React.useState("");
  const [errors, setErrors] = React.useState<ZodIssue[]>([]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFlagReason(e.target.value);

  const handleSubmit = () => {
    const { error } = FlagReasonSchema.safeParse({ flagReason });

    if (error) {
      setErrors(error.issues);
      return;
    }

    submit(id, flagReason);
  };

  return (
    <Modal
      closeButton
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <div className="flag_area_popup__wrapper">
        <img src={flaggedArea} alt="flagged area" />
        <div className="flag_area_popup__content">
          <h2>{flagged ? "Unflagged" : "Flag"} the Delivery Area</h2>
          <p>Are you certain about flagging this delivery area for caution?</p>
        </div>
        {!flagged && (
          <FormControl>
            <div className="flag_area_popup__input__label">Reason</div>
            <WingoInput
              name="flag_reason"
              placeholder="Please provide a reason"
              value={flagReason}
              onChange={handleOnChange}
            />
            <FormHelperText error>
              {errors.find(error => error.path[0] === "flagReason")?.message}
            </FormHelperText>
          </FormControl>
        )}
        <div className="flag_area_popup__buttons">
          <OutlinedButton onClick={onClose}>
            CANCEL
          </OutlinedButton>
          <ContainedButton onClick={handleSubmit}>
            PROCEED
          </ContainedButton>
        </div>
      </div>
    </Modal>
  );
};

export default FlagAreaPopup;
