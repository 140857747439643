import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  IGroup,
  IAccount,
  IGroupAccount,
  ITask,
  Status,
  Priority,
} from "./types";
import { ImageArray } from "../../../blocks/ordermanagement/src/OrderHistoryController";
import { accepted, orderPlaced, pickedUp } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  orderStatus: string;
  orderType: string;
  imageGroupCollection: ImageArray[];
  imageGroupDelivery: ImageArray[];
  orderStatusesDelivery: string[];
  orderStatusesCollection:string[];
  token: string;
  estimatedTimeTosend:string;
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  title: string;
  description: string;
  priority: string;
  status: string;
  assign_to: string;
  assign_to_type: string;
  editMode: boolean;
  token: string;
  taskList: ITask[];
  isVisibleModal: boolean;
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  groupList: IGroup[];
  statusList: Status[];
  priorityList: Priority[];
  selectedAccounts: IGroupAccount[];
  accountsData: IAccount[];
  orderTracker: any;
  copied: boolean;
  failedToCopy: boolean;
  trackOrderDetails:any;
  messageRegardingDriver:string;
  driverName:string;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  deleteTaskApiCallId = "";
  getGroupsApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";
  getTrackOrderApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      title: "",
      description: "",
      priority: "",
      status: "",
      assign_to: "",
      assign_to_type: "",
      editMode: false,
      token: "",
      taskList: [],
      isVisibleModal: false,
      isVisibleAssignModal: false,
      dropdownGroup: false,
      dropdownStatus: false,
      dropdownAccount: false,
      dropdownPriority: false,
      groupList: [],
      selectedAccounts: [],
      accountsData: [],
      statusList: [
        { id: 1, name: "to_do" },
        { id: 2, name: "in_progress" },
        { id: 3, name: "complete" },
      ],
      priorityList: [
        { id: 1, name: "low" },
        { id: 2, name: "medium" },
        { id: 3, name: "high" },
      ],
      orderTracker: {
        order_number: "",
          placed_at: "",
          status: "",
          order_type: "",
          estimated_time: "",
          total: "",
          restaurant: {
            title: "",
            location: "",
            contact: ""
          },
          delivery_address:[{
            id: 0,
            account_id: 0,
            name: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
            latitude:0,
            longituse:0
          }]
        },
        copied: false,
        failedToCopy: false,
        trackOrderDetails: null,
        messageRegardingDriver:"Your Delivery Rider will be assigned once the food is ready",
        driverName:"",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getOrderDetails(this.props.id);
  }

  async componentDidUpdate(prevProps:Props){
    if(prevProps.orderStatus !== this.props.orderStatus){
      this.getOrderDetails(this.props.id)
      if(this.props.orderStatus !== "ready" && this.props.orderStatus !== "in_transit"){
        this.setState({messageRegardingDriver:"Your Delivery Rider will be assigned once the food is ready"})
      }
    }
  }

  receive = async (from: string, message: Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const responseId = getName(MessageEnum.RestAPIResponceMessage); 
    this.handleResponse({responseId, message, apiRequestCallId, responseJson})
  };

  handleResponse = ({responseId, message, apiRequestCallId, responseJson}: any) => {
    if(responseId === message.id){
      if(apiRequestCallId === this.getTrackOrderApiCallId){
        const {data: {attributes: {order_number, placed_at, status, order_type, estimated_time, total, restaurant, delivery_addresses,driver_details}}} = responseJson.data;
        const [delivery_address] = delivery_addresses.length ? delivery_addresses : [this.state.orderTracker.delivery_address];
        this.setState({orderTracker: {order_number, placed_at, status, order_type, estimated_time, total, restaurant, delivery_address}})
        if(driver_details){
          this.setState({driverName:driver_details})
          if(status==='ready' || status==="in_transit"){
            this.setState({messageRegardingDriver:`${driver_details} is assigned as your Delivery Rider and will pick your order shortly.`})
          }
        }
      }
    }
  }

  getOrderDetails = (id:string)=>{
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": this.props.token
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTrackOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getOrderDetailsApiEndPoint}${id}/order_detail`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onStatusImages = (status: string) => {
    switch(status) {
      case 'accepted':
        return {image: accepted, status: 'Order Accepted'};
      case 'confirmed':
        return {image: accepted, status: 'Order Confirmed'};
        default:
          return {image: orderPlaced, status: 'Order Placed'};
    }
  }
  setDriverAssignationMessage=(message:string)=>{
    if(message==="Your order is on its way! Our driver is currently delivering another order but will be heading straight to you next. Thank you for your patience!"){
      this.setState({messageRegardingDriver:`Your order is on its way! ${this.state.driverName} is currently delivering another order but will be heading straight to you next. Thank you for your patience!`})
    } else if(message = "Your order has been picked up! Tracking the driver."){
      this.setState({messageRegardingDriver:`${this.state.driverName} is on the way to deliver your order`})
    }
    
  }
  // Customizable Area End
}
