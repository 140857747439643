import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SelectChangeEvent } from "@mui/material";
import moment from "moment-timezone";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { convertTimeToDateFormat, detectTimeFormat, getSessionStorage, getTimesByInterval } from "../../../components/src/utils";
import { Holiday } from "../../../components/src/Dashboard/Settings/types";
import { HolidaySchema } from "../../../components/src/Schemas/HolidaySchema";
import { weekSchema } from "../../../components/src/Schemas/SettingsSchema";
import { ZodIssue } from "zod";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  search: string;
  token: string;
  selectedTab: number;
  successPopup: {
    open: boolean;
    message?: string;
  };
  errorPopup: {
    open: boolean;
    message?: string;
  },
  time_zone: string;
  time_format: string;
  storeHours: { [day: string]: { [x: string]: string; } } | null;
  timeOptions: string[];
  estimated_delivery_time: number;
  estimated_collection_time: number;
  supports_delivery: boolean;
  supports_collection: boolean;
  primary_phone: string;
  holidays: Holiday[];
  addHolidayModal: {
    open: boolean;
    data: {
      date: string;
      reason: string;
    }
  };
  createHolidayErrors: { field: string | number; message: string; }[],
  saveSettingsError: ZodIssue[],
  confirmDeletePopup: {
    open: boolean;
    data?: {
      id?: number | null;
      date?: string;
      reason?: string
    };
  };
  openDrawer: boolean;
  selectedYear: number;
  [x: string]: unknown;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfpossettings1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSettingsCallId: string = "";
  updateSettingsCallId: string = "";
  getHolidaysCallId: string = "";
  addHolidayCallId: string = "";
  deleteHolidayCallId: string = "";

  timeZoneOptions: string[] = moment.tz.names();
  timeFormatOptions: string[] = ["12-hour", "24-hour"];
  weekdays: string[] = [];

  settings = getSessionStorage("restaurant_settings");
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      search: "",
      token: "",
      selectedTab: 0,
      successPopup: {
        open: false,
        message: ""
      },
      errorPopup: {
        open: false,
        message: ""
      },
      time_format: "",
      time_zone: "",
      storeHours: null,
      timeOptions: [],
      estimated_collection_time: 0,
      estimated_delivery_time: 0,
      supports_collection: false,
      supports_delivery: false,
      primary_phone: "",
      holidays: [],
      addHolidayModal: {
        open: false,
        data: {
          date: moment().format("yyyy-MM-DD"),
          reason: "",
        }
      },
      createHolidayErrors: [],
      saveSettingsError: [],
      confirmDeletePopup: {
        open: false,
        data: {
          id: null,
          date: "",
          reason: "",
        }
      },
      openDrawer: false,
      selectedYear: moment().year()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const error = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (!response) return;
    if (response?.errors?.[0]?.token) this.showAlert("token", "token");

    const handler = this.getMessageHandler(callId, response);
    if (handler) handler(response);
    if (error) runEngine.debugLog("API Error", error);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    await this.getToken();
    this.weekdays = moment.weekdays();
    this.weekdays.push(this.weekdays.shift() ?? "");
    this.getSettings();
    this.getHolidays();
  }

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    if (prevState.time_format !== this.state.time_format) this.setTimeOptions();
    if (prevState.time_zone !== this.state.time_zone) moment.tz.setDefault(this.state.time_zone);
  }

  getMessageHandler(callId: string, _response: any) {
    const messageHandlers = {
      [this.getSettingsCallId]: this.handleReceivedSettings,
      [this.updateSettingsCallId]: this.handleUpdatedSettings,
      [this.getHolidaysCallId]: this.handleReceivedHolidays,
      [this.deleteHolidayCallId]: this.handleDeletedHoliday,
      [this.addHolidayCallId]: this.handleAddHoliday,
    };

    return messageHandlers[callId];
  };

  getToken = async () => {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  };

  getSettings = () => {
    this.setState({ saveSettingsError: [] });

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSettingsCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.settingsEndPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getHolidays = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getHolidaysCallId = getDataMsg.messageId;
    const year = this.state.selectedYear ? `?year=${this.state.selectedYear}` : null

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHolidaysEndPoint + year
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  deleteHoliday = () => {
    const data = this.state.confirmDeletePopup.data;
    const body = {
      holiday: { date: data?.date, reason: data?.reason }
    };
    const deleteMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteHolidayCallId = deleteMsg.messageId;
    deleteMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteHoldayEndPoint + `/${data?.id}`
    );
    deleteMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    deleteMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    deleteMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(deleteMsg.id, deleteMsg);
  };

  addHoliday = () => {
    this.setState({ createHolidayErrors: [] });

    const data = this.state.addHolidayModal.data;

    const { error } = HolidaySchema.safeParse(this.state.addHolidayModal.data);

    if (error) {
      const errors = error.errors.map((err) => ({
        field: err.path[0],
        message: err.message,
      }));

      this.setState({ createHolidayErrors: errors });
      return;
    };

    const body = {
      holiday: data
    };

    const addDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addHolidayCallId = addDataMsg.messageId;
    addDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addHolidayEndPoint
    );
    addDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    addDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    addDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(addDataMsg.id, addDataMsg);
  };

  updateSettings = () => {
    const body = {
      restaurant: {
        time_format: this.state.time_format,
        time_zone: this.state.time_zone,
        store_hours: this.state.storeHours
        ? Object.fromEntries(
            Object.entries(this.state.storeHours).map(([day, times]) => [
              day,
              {
                open: moment(times.open, ["hh:mm A"]).format("HH:mm"),
                close: moment(times.close, ["hh:mm A"]).format("HH:mm"),
              },
            ])
          )
        : {},
        estimated_delivery_time: this.state.estimated_delivery_time,
        estimated_collection_time: this.state.estimated_collection_time,
        supports_delivery: this.state.supports_delivery,
        supports_collection: this.state.supports_collection,
        primary_phone: this.state.primary_phone,
      }
    };

    const { error } = weekSchema.safeParse(body.restaurant.store_hours);

    if (error) {
      this.setState({ saveSettingsError: error.issues });
      return error.issues;
    };

    const updateDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateSettingsCallId = updateDataMsg.messageId;
    updateDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSettingsEndPoint
    );
    updateDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    updateDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    updateDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    runEngine.sendMessage(updateDataMsg.id, updateDataMsg);
  };

  handleAddHoliday = (response: any) => {
    if (response?.message) {
      this.setState({
        addHolidayModal: {
          open: false,
          data: {
            date: moment().format("yyyy-MM-DD"),
            reason: ""
          }
        },
        successPopup: {
          open: true,
          message: response?.message
        }
      }, this.getHolidays);
    };

    if (response?.errors) {
      this.setState({
        addHolidayModal: {
          open: false,
          data: {
            date: moment().format("yyyy-MM-DD"),
            reason: ""
          }
        },
        errorPopup: {
          open: true,
          message: response?.errors?.[0]
        }
      })
    }
  };

  handleDeletedHoliday = (response: any) => {
    if (response?.message) {
      this.setState({
        successPopup: {
          open: true,
          message: response?.message
        }
      }, () => {
        this.confirmDeletePopupClose();
        this.getHolidays();
      });
    };

    if (response?.errors) {
      this.setState({
        errorPopup: {
          open: true,
          message: response?.errors
        }
      }, this.confirmDeletePopupClose);
    };
  };

  handleReceivedHolidays = (response: any) => {
    this.setState({ holidays: [] });

    if (response?.holidays) {
      this.setState({
        holidays: response?.holidays
      });
    };
  };

  handleReceivedSettings = (response: any) => {
    sessionStorage.setItem("settings", JSON.stringify(response));

    this.setState({
      time_format: response?.time_format,
      time_zone: response?.time_zone,
      storeHours: response?.store_hours,
      estimated_delivery_time: response?.estimated_delivery_time,
      estimated_collection_time: response?.estimated_collection_time,
      supports_delivery: response?.supports_delivery,
      supports_collection: response?.supports_collection,
      primary_phone: response?.primary_phone,
    });
  };

  handleUpdatedSettings = (response: any) => {
    if (response?.message && response?.settings) {
      this.setState({
        successPopup: {
          open: true,
          message: response?.message
        },
        time_format: response?.settings?.time_format,
        time_zone: response?.settings?.time_zone,
        storeHours: response?.settings?.store_hours,
        estimated_delivery_time: response?.settings?.estimated_delivery_time,
        estimated_collection_time: response?.settings?.estimated_collection_time,
        supports_delivery: response?.settings?.supports_delivery,
        supports_collection: response?.settings?.supports_collection,
        primary_phone: response?.settings?.primary_phone,
      }, () => {
        sessionStorage.setItem("restaurant_settings", JSON.stringify(response?.settings));
      });
    }
  };

  setTimeOptions = () => {
    this.setState({
      timeOptions: getTimesByInterval(
        "00:00",
        "23:59",
        15,
        this.state.time_format === "12-hour" ? "hh:mm A" : "HH:mm"
      ),
    });
  };

  handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.setState({ search: e.target.value });

  setSelectedTab = (_event: React.SyntheticEvent<Element, Event>, value: number) =>
    this.setState({ selectedTab: value });

  handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleStoreHoursChange = (event: SelectChangeEvent<unknown>) => {
    const { value, name } = event.target;

    const weekday = name.split("+")[0];
    const key = name.split("+")[1];

    const format = detectTimeFormat(value as string);
    const is24HourFormat = format === "24-hour";

    this.setState(prevState => {
      this.setState({ saveSettingsError: [] });
      const updatedStoreHours = { ...prevState.storeHours };
      const existingIndex = updatedStoreHours?.[weekday];

      if (existingIndex) {
        updatedStoreHours[weekday] = {
          ...updatedStoreHours[weekday],
          [key]: moment(value as string, is24HourFormat ? ["HH:mm"] : ["hh:mm A"]).format("HH:mm")
        };
      } else {
        updatedStoreHours[weekday] = { [key]: moment(value as string, is24HourFormat ? ["HH:mm"] : ["hh:mm A"]).format("HH:mm") };
      }

      return { storeHours: updatedStoreHours };
    });
  };

  handleSuccessPopupClose = () =>
    this.setState({
      successPopup: {
        open: false,
        message: ""
      }
    });

  handleErrorPopupClose = () =>
    this.setState({
      errorPopup: {
        open: false,
        message: ""
      }
    });

  confirmDeletePopupOpen = (data: { id: number; date: string; reason: string; }) =>
    this.setState({
      confirmDeletePopup: {
        open: true,
        data: {
          id: data.id,
          date: data.date,
          reason: data.reason
        }
      }
    });

  confirmDeletePopupClose = () =>
    this.setState({
      confirmDeletePopup: {
        open: false,
        data: {
          id: null,
          date: "",
          reason: ""
        }
      }
    });

  handleOpenAddHolidayModal = () =>
    this.setState({ addHolidayModal: { open: true, data: { date: "", reason: "" } } });

  handleCloseAddHolidayModal = () =>
    this.setState({ addHolidayModal: { open: false, data: { date: "", reason: "" } } });

  handleDateChange = (date: Date | null) => {
    this.setState({
      addHolidayModal: {
        ...this.state.addHolidayModal,
        data: {
          ...this.state.addHolidayModal.data,
          date: moment(date).format("yyyy-MM-DD")
        }
      }
    });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState({
      addHolidayModal: {
        ...this.state.addHolidayModal,
        data: {
          ...this.state.addHolidayModal.data,
          reason: value
        }
      }
    });
  };

  handleDrawerOpen = () =>
    this.setState({
      openDrawer: true
    });

  handleDrawerClose = () =>
    this.setState({
      openDrawer: false
    });

  handleHolidayFilterChange = (event: SelectChangeEvent<unknown>) =>
    this.setState({
      selectedYear: parseInt(event.target.value as string)
    }, this.getHolidays);
  // Customizable Area End
}
