import React from "react";
import { Event, View, ViewsProps } from "react-big-calendar";
import Calendar from "./Calendar";
import "./Scheduler.css";

interface Props {
  selectedStaff: number | null;
  selectedRole: string | null;
  recievedView?: View;
  recievedViews?: ViewsProps<Event, object>;
  events: Event[];
  updateEvents: (events: Event[]) => void;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
  totalShiftHours: string;
  openLogin: (event: Event) => void;
  gaps: Record<string, string[]> | null;
  conflicts: Record<string, string[]> | null;
  openWeeklyNotes: (start: string, end: string) => void;
}

const StaffDayScheduler = React.memo(({ selectedStaff, selectedRole, events, updateEvents, recievedView, recievedViews, openShiftPopup, totalShiftHours, openLogin, gaps, conflicts, openWeeklyNotes }: Props) => {
  return (
    <div className="scheduler__wrapper">
      <div className="scheduler__content">
        <Calendar
          selectedStaff={selectedStaff}
          selectedRole={selectedRole}
          recievedView={recievedView}
          recievedViews={recievedViews}
          events={events}
          updateEvents={updateEvents}
          openShiftPopup={openShiftPopup}
          totalShiftHours={totalShiftHours}
          openLogin={openLogin}
          gaps={gaps}
          conflicts={conflicts}
          openWeeklyNotes={openWeeklyNotes}
        />
      </div>
    </div>
  );
});

export { StaffDayScheduler };
