import React from "react";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import Dropzone, { DropzoneProps } from "react-dropzone";
import { formatSize } from "../utils";
import { ReceivedFile } from "../../../blocks/dashboard/src/dashboards/StaffInformation/StaffInformationController.web";
import "./Dropzone.css";
import { DownloadIcon } from "../Icons";

interface Props extends DropzoneProps {}

const WingosDropzone = (props: Props) => {
  return (
    <Dropzone
      {...props}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="wingos_dropzone">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="wingos_dropzone__wrapper">
              <SvgIcon>
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.667 3.336h11.666c.925 0 1.667.75 1.667 1.667v10c0 .916-.75 1.666-1.667 1.666h-2.5a.836.836 0 0 1-.833-.833c0-.458.375-.833.833-.833h2.5V6.669H4.667v8.334h2.5c.458 0 .833.375.833.833a.836.836 0 0 1-.833.833h-2.5A1.67 1.67 0 0 1 3 15.003v-10c0-.917.742-1.667 1.667-1.667m3.216 7.617 2.325-2.325a.41.41 0 0 1 .584.008l2.325 2.325a.413.413 0 0 1-.292.708h-1.492v4.167a.836.836 0 0 1-.833.833.836.836 0 0 1-.833-.833v-4.167H8.175a.418.418 0 0 1-.292-.716"
                    fill="#747474"
                  />
                </svg>
              </SvgIcon>
              <span className="wingos_dropzone__text">
                <span className="wingos_dropzone__text__highlighted">Browse files</span> to attach
              </span>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

interface WingosPreviewProps extends Props {
  files: (File | ReceivedFile)[];
  removeFile?: () => void;
  download?: boolean;
}

const isReceivedFile = (file: File | ReceivedFile): file is ReceivedFile => {
  return (file as ReceivedFile).url !== undefined;
};

const WingosPreview = ({ files, removeFile, download = false }: WingosPreviewProps) => {
  const previews = files.map((file) => {
    if (isReceivedFile(file)) {
      return file;
    } else {
      return Object.assign(file, {
        url: URL.createObjectURL(file),
        content_type: file.type,
        file_name: file.name,
      });
    }
  });

  const handleDownload = (file: File | ReceivedFile) => {
    const link = document.createElement('a');
    if (isReceivedFile(file)) {
      link.href = file.url; // AWS S3 bucket URL
      link.download = file.file_name; // Optional, specifies the name for the downloaded file
    } else return;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="wingos_dropzone_preview">
      {previews.map((file) => (
        <div
          className="wingos_dropzone_preview__item"
          key={file.file_name}
        >
          {file.content_type !== "image/png" ? (
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="56"
                height="56"
                rx="8"
                fill="#F6B8BA"
              />
              <path
                d="M37 27.01 19 27v2h18zM19 32h12v2H19zm18-10H19v2.01L37 24z"
                fill="#E11B22"
              />
            </svg>
          ) : (
            <img
              src={file.url}
              alt={file.file_name}
              className="wingos_dropzone_preview__item__image"
            />
          )}

          <div className="wingos_dropzone_preview__item__details">
            <Tooltip
              arrow
              title={file.file_name}
              placement="right"
            >
              <span className="wingos_dropzone_preview__item__filename">
                {file.file_name}
              </span>
            </Tooltip>
            <span className="wingos_dropzone_preview__item__filesize">
              {file.size ? formatSize(file.size) : file.content_type}
            </span>
          </div>

          {removeFile && (
            <IconButton
              color="error"
              onClick={removeFile}
            >
              <SvgIcon>
                <svg
                  width="800"
                  height="800"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 6h-4V5a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v1H4a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2M10 5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4Zm7 14a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8h10Z" />
                </svg>
              </SvgIcon>
            </IconButton>
          )}

          {download && (
            <IconButton onClick={() => handleDownload(file)}>
              <DownloadIcon />
            </IconButton>
          )}
        </div>
      ))}
    </section>
  );
};

export { WingosDropzone, WingosPreview };
