import React from "react";
// Customizable Area Start
import { StyleSheet, Dimensions, Platform } from "react-native";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Polyline
} from '@react-google-maps/api';
import { anchor, MapsMap, origin, scaledSize } from "../../../components/src/MapsDistanceRouting";
import { currentLocation, driverLocation, restaurantLocation } from "./assets";
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  generateCurve = (start:{lat:number|string|null,long:number|string|null}, end:{lat:number|string|null,long:number|string|null}) => {
    const points:google.maps.LatLngLiteral[] = [];
    const curveFactor = 0.2;

    const controlLat =
      (Number(start.lat) + Number(end.lat)) / 2 + curveFactor * Math.abs(Number(start.long) - Number(end.long));
    const controlLong =
      (Number(start.long) + Number(end.long)) / 2 - curveFactor * Math.abs(Number(start.lat) - Number(end.lat));

    const numPoints = 100; 
    for (let i = 0; i <= numPoints; i++) {
      const t = i / numPoints;
      const lat =
        (1 - t) * (1 - t) * Number(start.lat) +
        2 * (1 - t) * t * controlLat +
        t * t * Number(end.lat);
      const long =
        (1 - t) * (1 - t) * Number(start.long) +
        2 * (1 - t) * t * controlLong +
        t * t * Number(end.long);
      points.push({ lat, lng:long });
    }
    return points;
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const customStartIcon = {
      url: this.props.messageDriver.includes("Thank you for your patience!")?restaurantLocation : driverLocation

    };
    const customEndIcon = {
      url: currentLocation, scaledSize,
      origin,
      anchor
    };
    return (
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMap
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map: MapsMap) => this.setState({ map })}
        >
          {this.state.directionsResponse && (
            <>
           <Marker icon={customStartIcon} position={this.state.directionsResponse.routes[0].legs[0].start_location}/>
            <Marker position={this.state.directionsResponse.routes[0].legs[0].end_location} icon={customEndIcon}/>
            {this.props.messageDriver.includes("Thank you for your patience!") && <Polyline path={this.generateCurve(this.state.origin,this.state.destination)}  options={{strokeColor: '#CD191F', geodesic: true,strokeWeight: 2, strokeOpacity: 1.0}}/>}
              <DirectionsRenderer directions={this.state.directionsResponse}  options={{ suppressMarkers: true,  polylineOptions: {strokeColor: '#CD191F',strokeWeight:this.props.messageDriver.includes("Thank you for your patience!")? 0:4, strokeOpacity: 1.0,geodesic: true }}} />
            </>
          )}
        </GoogleMap>
  </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  map: {
    width: viewportWidth,
    height: viewportHeight,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
});
// Customizable Area End
